import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component } from "react";
import { Link } from "react-router-dom";

export default class TaskList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // See App.js for more details. loadRecipe is defined there.
      props,
    };
  }
  render() {
    return (
      <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="task list">
          <TableHead>
            <TableRow>
              <TableCell>claim</TableCell>
              <TableCell>Case ReferenceNo</TableCell>
              <TableCell>CIF No.</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>RM Name</TableCell>
              <TableCell>Current Stage</TableCell>
              <TableCell>Case Status</TableCell>
              <TableCell>Data Source</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell>Pending Since</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>view</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.list.map((row) => (
              <TableRow
                key={row.auditId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <a
                    href={`${process.env.REACT_APP_EP_TaskRun}${row.referenceNo}`}
                    target={`run-${row.referenceNo}`}
                    onClick={(ev) => {
                      ev.preventDefault();

                      window.open(
                        ev.currentTarget.href,
                        ev.currentTarget.target
                        // `location=no,height=${window.screen.height},width=${window.screen.width},scrollbars=no,status=no`
                      );
                      return false;
                    }}
                  >
                    RunTask
                  </a>
                </TableCell>
                <TableCell>{row.referenceNo}</TableCell>
                <TableCell>{row.cifNo}</TableCell>
                <TableCell>{row.customerName}</TableCell>
                <TableCell>{row.rmName}</TableCell>
                <TableCell>{row.currentStep}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.source}</TableCell>
                <TableCell>{row.creationDate}</TableCell>
                <TableCell>{row.assignedTime}</TableCell>
                <TableCell>{row.priority}</TableCell>
                <TableCell>
                  <Link
                    to={`/View/${row.referenceNo}`}
                    target={`view-${row.referenceNo}`}
                  >
                    View
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
