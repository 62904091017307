import { Component } from "react";
import Layout from "./Layout";

import FormComp from "../FormComp";
import "../styles/App.css";
import { Box, Tab, Tabs } from "@mui/material";
import CaseDetail from "../Components/TLComponents/CaseDetail";
import PersonaDetail from "../Components/TLComponents/PersonaDetail";
import RelatedCompanies from "../Components/TLComponents/RelatedCompanies";
import OwnershipStructure from "../Components/TLComponents/OwnershipStructure";
import TLDetails from "../Components/TLComponents/TLDetails";

import TabPanel from "../Components/CommonUiComponents/TabPanel";
import Comments from "../Components/CommonUiComponents/Comments";
import CaseHistory from "../Components/CommonUiComponents/CaseHistory";
import FieldAudit from "../Components/CommonUiComponents/FieldAudit";
import EName from "../Components/CommonUiComponents/EName";

export default class View extends Component {
  state = { activeTab: 0, filteredList: [] };
  handleChange = (event, activeTab) => {
    this.setState({ activeTab });
  };
  render() {
    console.log(this.state, this.props);
    return (
      <Layout title={`View Details ${this.props.referenceNo}`}>
        <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
          <Box sx={{ flexBasis: "50%", overflow: "auto" }}>
            <CaseDetail refNo={this.props.referenceNo}></CaseDetail>
            <PersonaDetail refNo={this.props.referenceNo}></PersonaDetail>
            <RelatedCompanies refNo={this.props.referenceNo}></RelatedCompanies>
            <OwnershipStructure
              refNo={this.props.referenceNo}
            ></OwnershipStructure>
            <TLDetails
              refNo={this.props.referenceNo}
              type="CURRENT"
            ></TLDetails>

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={this.state.activeTab} onChange={this.handleChange}>
                  <Tab
                    label={`E-Name Screening`}
                    id="tabEnameAuto"
                    aria-controls="tabEnameAutoPanel"
                  />
                  <Tab
                    label={`Manual E-Name Screening`}
                    id="tabManualEnameScreening"
                    aria-controls="tabManualEnameScreeningPanel"
                  />
                  <Tab
                    label={`Commets`}
                    id="tabComments"
                    aria-controls="tabCommentsPanel"
                  />
                  <Tab
                    label={`Case History`}
                    id="tabCaseHistory"
                    aria-controls="tabCaseHistoryPanel"
                  />
                  <Tab
                    label={`Field Audit`}
                    id="tabFieldAudit"
                    aria-controls="tabFieldAuditPanel"
                  />
                </Tabs>
              </Box>
              <TabPanel value={this.state.activeTab} index={0}>
                <EName type="Auto" refNo={this.props.referenceNo} />
              </TabPanel>
              <TabPanel value={this.state.activeTab} index={1}>
                <EName type="Manual" refNo={this.props.referenceNo} />
              </TabPanel>
              <TabPanel value={this.state.activeTab} index={2}>
                <Comments refNo={this.props.referenceNo} />
              </TabPanel>
              <TabPanel value={this.state.activeTab} index={3}>
                <CaseHistory refNo={this.props.referenceNo} />
              </TabPanel>
              <TabPanel value={this.state.activeTab} index={4}>
                <FieldAudit refNo={this.props.referenceNo} />
              </TabPanel>
            </Box>
          </Box>
          <Box sx={{ flexBasis: "50%", overflow: "auto" }}>
            <small>
              You are running this application in <b>{process.env.NODE_ENV}</b>{" "}
              mode.
            </small>
            <FormComp></FormComp>
          </Box>
        </Box>
      </Layout>
    );
  }
}
