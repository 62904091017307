import React, { Component } from "react";
import {
  callBpmAjax,
  formatDate,
  dateDiffDays,
} from "../Helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default class RelatedCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      items: [],
      selected: [],
    };
  }
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_RelatedCIFs, {
      refNo: this.props.refNo,
    }).then((d) => {
      this.setState({ items: d });
    });
  }

  state = {};
  render() {
    let now = new Date();
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="RelatedCompanies-content"
          id="RelatedCompanies-header"
        >
          <Typography variant="h5">Related Companies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table className="RelatedCompanies">
            <TableHead>
              <TableRow role="row">
                <TableCell>CIF</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>License Number</TableCell>
                <TableCell>Issuing Authority</TableCell>
                <TableCell>License Expiry Date</TableCell>
                <TableCell>Aging</TableCell>
                <TableCell>Un-Tag Comment</TableCell>
                <TableCell>Tagged</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.items.map((item) => (
                <TableRow key={item.cif}>
                  <TableCell>{item.cif}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.tlNumber}</TableCell>
                  <TableCell>{item.issuingAuthority}</TableCell>
                  <TableCell>{formatDate(item.expiryDate)}</TableCell>
                  <TableCell>{dateDiffDays(now, item.expiryDate)}</TableCell>
                  <TableCell>{item.comments}</TableCell>
                  <TableCell>{item.selected ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    );
  }
}

