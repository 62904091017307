import { Component } from "react";
import { callBpmAjax } from "../Helpers";
import { Navigate } from "react-router-dom";

export default class User extends Component {
  state = {
    name: "...",
    list: [],
    authenticated: true,
  };
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_UserDetails, {}).then(
      (response) => {
        console.log(response);

        let user = response.pop();
        this.setState({
          name: `${user.firstName} ${user.lastName}`,
          authenticated: true,
        });
      },
      (errResp) => {
        console.log(errResp);
        this.setState({ authenticated: false });
      }
    );
  }
  render() {
    if (this.state.authenticated) return this.state.name;
    // else return <Navigate replace to="/login" />;
  }
}
