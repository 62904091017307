import React, { Component } from "react";

import "./CaseDetail.css";
import DisplayText from "../CommonUiComponents/DisplayText";
import { callBpmAjax } from "../Helpers";
import { Grid } from "@mui/material";

export default class CaseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cifNo: "",
      status: "",
      source: "",
      mobNo: "",
      eMailId: "",
    };
  }
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_CaseDetails, {
      refNo: this.props.refNo,
    }).then((d) => {
      this.setState(d);
    });
  }

  state = {};
  render() {
    return (
      <Grid container>
        <Grid item xs={12} md={3}>
          <DisplayText label="CIF" value={this.state.cifNo}></DisplayText>
        </Grid>
        <Grid item xs={12} md={3}>
          <DisplayText
            label="Case Status"
            value={this.state.caseStatus}
          ></DisplayText>
        </Grid>
        <Grid item xs={12} md={3}>
          <DisplayText label="Source" value={this.state.source}></DisplayText>
        </Grid>
        <Grid item xs={12} md={3}>
          <DisplayText
            label="Registered Mobile"
            value={this.state.registeredMobile}
          ></DisplayText>
        </Grid>
        <Grid item xs={12} md={3}>
          <DisplayText
            label="Registered Mail ID"
            value={this.state.registeredEMail}
          ></DisplayText>
        </Grid>
      </Grid>
    );
  }
}
