import React, { Component } from "react";
import { callBpmAjax, formatDateTime } from "../Helpers";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

export default class EName extends Component {
  state = {
    list: [],
    page: 0,
    rowsPerPage: 5,
  };
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_Ename, {
      refNo: this.props.refNo,
      type: this.props.type,
    }).then((d) => {
      this.setState({ list: d });
    });
  }

  render() {
    let dispList = this.state.list.slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    );
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="comment">
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date Time</TableCell>
              <TableCell>Comment Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dispList.map((c) => (
              <TableRow key={c.enameSeqId}>
                <TableCell>{c.userName}</TableCell>
                <TableCell>{c.userRole}</TableCell>
                <TableCell>{c.caseStatus}</TableCell>
                <TableCell>{formatDateTime(c.commentDate)}</TableCell>
                <TableCell>{c.commentDescription}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.list.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={(ev, page) => {
            this.setState({ page });
          }}
          onRowsPerPageChange={(ev) => {
            this.setState({ rowsPerPage: +ev.target.value });
          }}
        />
      </TableContainer>
    );
  }
}
