export const config = {
  bpmDe: "",
  bpmAppAccronym: "CORPTLM",
  env: "local",
};
const months = [
  "Jan",
  "Feb",
  "Mar",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function formatDate(dt) {
  if (!dt) {
    return "";
  }
  let x = new Date(dt);
  let dd = String(x.getDate()).padStart(2, "0");
  let mon = months[x.getMonth()];
  let yyyy = x.getFullYear();
  return `${dd}-${mon}-${yyyy}`;
}
export function formatDateTime(dt) {
  let x = new Date(dt);
  let dd = String(x.getDate()).padStart(2, "0");
  let mon = months[x.getMonth()];
  let yyyy = x.getFullYear();
  let hh = String(x.getHours() % 12).padStart(2, "0");
  let min = String(x.getMinutes()).padStart(2, "0");
  let ss = String(x.getSeconds()).padStart(2, "0");
  let amPm = x.getHours() > 12 ? "PM" : "AM";
  return `${dd}-${mon}-${yyyy} ${hh}:${min}:${ss} ${amPm}`;
}
export function dateDiffDays(date1, date2) {
  let x = new Date(date1);
  let y = new Date(date2);
  return Math.ceil(Math.abs(y - x) / (1000 * 3600 * 24));
}
// export function callBpmAjax(serviceName, data) {
// 	let url, method, body;
// 	if (config.env === "local") {
// 		url = `testData/${serviceName}.json`;
// 		method = "get";
// 		body = null;
// 	} else {
// 		url = `${config.bpmDe}/rest/bpm/wle/v1/service/${config.bpmAppAccronym}@${serviceName}`;
// 		method = "post";
// 		body = `action=start&createTask=false&parts=all&params=${encodeURIComponent(
// 			JSON.stringify(data)
// 		)}`;
// 	}
// 	return fetch(url, {
// 		method,
// 		headers: {
// 			"Content-Type": "application/x-www-form-urlencoded",
// 			Accept: "application/json",
// 		},
// 		body,
// 	})
// 		.then(async (d) => {
// 			if (d.ok) {
// 				return d.json();
// 			}
// 			let body = await d.text();
// 			let status = d.status;
// 			let statusText = d.statusText;
// 			throw new Error(JSON.stringify({ body, status, statusText }));
// 		})
// 		.then(
// 			(d) => {
// 				console.log(d);
// 				Object.keys(d.data.data).forEach((key) => {
// 					delete d.data.data[key]["@metadata"];
// 				});
// 				return d.data.data;
// 			},
// 			(e) => {
// 				console.warn(e);
// 				return e;
// 			}
// 		);
// }
export function getSearchParam(key) {
  let search = new URLSearchParams(window.location.search);
  return search.get(key);
}

export function callBpmAjax(serviceName, data = {}, method = "get", headers) {
  let url, body;
  url = `${serviceName}`;

  switch (method.toLowerCase()) {
    case "get":
      let qs = Object.keys(data)
        .map((k) => `${k}=${encodeURIComponent(data[k])}`)
        .join("&");
      url += "?" + qs;
      break;
    default:
      body = data;
  }
  headers = Object.assign(
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    headers
  );
  console.log(url, headers);
  return fetch(url, {
    method,
    headers,
    body,
  }).then(
    async (d) => {
      if (d.ok) {
        return d.json();
      }
      let body = await d.text();
      let status = d.status;
      let statusText = d.statusText;
      throw { body, status, statusText };
    },
    async (err) => {
      let body = err.message;
      let status = err.status;
      let statusText = err.statusText;
      throw { body, status, statusText };
    }
  );
}
