import React from "react";
import "./DisplayText.css";

export default function DisplayText(props) {
  return (
    <div className="DisplayText" style={props.style}>
      <span className="label">{props.label}</span>
      <span className="value">{props.value}</span>
    </div>
  );
}
