import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { Home, Task, View, NoPage } from "./Pages/Pages";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import theme from "./theme";
import Login from "./Pages/Login";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/task/:taskId">
          <Route index element={<TaskScreen />} />
          <Route path="/task/:taskId/:callBack" element={<TaskScreen />} />
        </Route>
        <Route path="/view/:referenceNo" element={<ViewScreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

function TaskScreen() {
  let params = useParams();
  console.log(params);
  return <Task {...params} />;
}

function ViewScreen() {
  let params = useParams();

  return <View {...params} />;
}

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App callback={() => console.log("Blog rendered")} />
  </ThemeProvider>
);

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
