import React, { Component } from "react";
// import Navbar from "../Navbar";
// import "../styles/Layout.css";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box, Grid } from "@mui/material";
import User from "../Components/CommonUiComponents/User";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  isOpen = () => this.state.isOpen;

  render() {
    return (
      <Container maxWidth="false">
        <Grid
          container
          sx={{
            backgroundColor: "primary.main",
            color: "#ffffff",
          }}
        >
          <Grid item xs={12} md={11}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ my: 2 }}
              align="center"
            >
              {this.props.title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <User />
          </Grid>
        </Grid>
        <Box id="main" sx={{ height: "calc(100vh - 95px)", overflow: "auto" }}>
          {this.props.children}
        </Box>
        <Box>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default Layout;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
