import React, { Component } from "react";
import { callBpmAjax, formatDateTime } from "../Helpers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export default class CaseHistory extends Component {
  // constructor(props) {
  // 	super(props);
  // }
  state = {
    collapsed: true,
    list: [],
  };
  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_CaseHistory, {
      refNo: this.props.refNo,
    }).then((d) => {
      this.setState({ list: d });
    });
  }

  render() {
    return (
      <Table>
        <TableHead>
          <TableRow className="CaseHistory">
            <TableCell>Queue Type</TableCell>
            <TableCell>Login User</TableCell>
            <TableCell>Login Time</TableCell>
            <TableCell>Logout Time</TableCell>
            <TableCell>case Status</TableCell>
            <TableCell>Action Taken</TableCell>
            <TableCell>Total Work Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.list.map((c) => (
            <TableRow>
              <TableCell>{c.queueType}</TableCell>
              <TableCell>{c.loginUser}</TableCell>
              <TableCell>{formatDateTime(c.loginTime)}</TableCell>
              <TableCell>{formatDateTime(c.logoutTime)}</TableCell>
              <TableCell>{c.caseStatus}</TableCell>
              <TableCell>{c.actionTaken}</TableCell>
              <TableCell>{c.totalWorkTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
