import React, { Component } from "react";
import { callBpmAjax } from "../Helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default class PersonaDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          personaName: "",
          personaMailId: "",
          personaMobileNo: "",
        },
      ],
      selected: [],
    };
  }
  state = {};
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_Personas, {
      refNo: this.props.refNo,
    }).then((d) => this.setState({ items: d }));
  }

  render() {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="PersonaDetails-content"
          id="PersonaDetails-header"
        >
          <Typography variant="h5">KYC Persona Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table className="PersonaDetail">
            <TableHead>
              <TableRow>
                <TableCell>Persona Name</TableCell>
                <TableCell>Persona Mail ID</TableCell>
                <TableCell>Persona Mobie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.items.map((item) => (
                <TableRow key={item.personaName}>
                  <TableCell>{item.personaName}</TableCell>
                  <TableCell>{item.personaMailId}</TableCell>
                  <TableCell>{item.personaMobileNo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    );
  }
}
