import React, { Component } from "react";
import { callBpmAjax, formatDateTime } from "../Helpers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export default class Comments extends Component {
  // constructor(props) {
  // 	super(props);
  // }
  state = {
    list: [],
  };
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_Comments, {
      refNo: this.props.refNo,
    }).then((d) => {
      this.setState({ list: d });
    });
  }

  render() {
    return (
      <Table>
        <TableHead>
          <TableRow className="comment">
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Time</TableCell>
            <TableCell>Comment Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.list.map((c) => (
            <TableRow>
              <TableCell>{c.userName}</TableCell>
              <TableCell>{c.userRole}</TableCell>
              <TableCell>{c.caseStatus}</TableCell>
              <TableCell>{formatDateTime(c.commentDate)}</TableCell>
              <TableCell>{c.commentDescription}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
