import { Component } from "react";
import Layout from "./Layout";
import "../styles/Home.css";

import { Box, Tab, Tabs } from "@mui/material";

import { callBpmAjax } from "../Components/Helpers";
import TaskList from "../Components/CommonUiComponents/TaskList";
import Search from "../Components/TLComponents/Search";
import TabPanel from "../Components/CommonUiComponents/TabPanel";

export default class Home extends Component {
  state = {
    allTasks: [],
    mode: "loading",
    filterFn: (a) => a,
    filteredList: [],
    activeTab: 0,
  };

  getTask = () => {
    callBpmAjax(process.env.REACT_APP_EP_TaskDetailsById, {
      audit_id:
        "42666_530890,42667_530888,42668_530892,42669_530895,42670_530899,42709_530915,42711_530906,42712_530909,42713_530910,42714_530916,42717_530920,42730_530925,42731_530926,42738_549274,42739_530927,42740_530937,42766_530928,43252_550097,43293_550292,43295_550272,43296_550283,43297_550365,43298_550328,43300_550290,43302_550309,43303_550054,43304_550060,43305_550094,43306_550113,43307_550121,43308_550120,43309_550137,43323_551090,43324_551111,43325_551042,43328_551047,43329_551046,43330_551097,43332_551098,43333_551052,43336_551085,43340_551113,43347_553398,43348_553391,43351_553403,43353_550313,43355_550333,43356_550305,43359_550359,43361_550273",
    }).then((allTasks) => {
      this.setState({ allTasks }, () => {
        this.runFilter();
        setTimeout(this.getTask, 10000);
      });
    });
  };
  componentDidMount() {
    this.setFilter("cmn");
    this.getTask();
  }

  setFilter = (filterName) => {
    let activeFilter = this.filters[filterName];
    this.setState({ filterFn: activeFilter, mode: "task" }, this.runFilter);
  };

  runFilter = () => {
    let filteredList = this.state.allTasks.filter(this.state.filterFn);
    this.setState({ filteredList });
  };

  handleChange = (event, activeTab) => {
    let activePanelType = event.target.dataset.mode || "q";
    this.setState({ activeTab, activePanelType }, () =>
      this.setFilter(event.target.dataset.filtername || "cmn")
    );
  };

  render() {
    return (
      <Layout className="home" title="Home">
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={this.state.activeTab} onChange={this.handleChange}>
              <Tab
                label={`Common Queue (${
                  this.state.allTasks.filter(this.filters.cmn).length
                })`}
                id="tabBtnQueueCmn"
                aria-controls="tabBtnQueueCmnPanel"
                data-filtername="cmn"
                data-mode="q"
              />
              <Tab
                label={`My Queue (${
                  this.state.allTasks.filter(this.filters.myq).length
                })`}
                id="tabBtnQueueMyq"
                aria-controls="tabBtnQueueMyqPanel"
                data-filtername="myq"
                data-mode="q"
              />
              <Tab
                label={`Referral Queue (${
                  this.state.allTasks.filter(this.filters.ref).length
                })`}
                id="tabBtnQueueRef"
                aria-controls="tabBtnQueueRefPanel"
                data-filtername="ref"
                data-mode="q"
              />
              <Tab
                label={`Awaiting Customer Responce (${
                  this.state.allTasks.filter(this.filters.cust).length
                })`}
                id="tabBtnQueueCust"
                aria-controls="tabBtnQueueCustPanel"
                data-filtername="cust"
                data-mode="q"
              />
              <Tab
                label={`Flex Retry (${
                  this.state.allTasks.filter(this.filters.flx).length
                })`}
                id="tabBtnQueueFlx"
                aria-controls="tabBtnQueueFlxPanel"
                data-filtername="flx"
                data-mode="q"
              />
              <Tab
                label="Search"
                id="tabBtnSearch"
                aria-controls="tabBtnSearchPanel"
                data-mode="s"
              />
              <Tab
                label="More Services"
                id="tabBtnMorSvc"
                aria-controls="tabBtnMorSvcPanel"
                data-mode="l"
              />
            </Tabs>
          </Box>
          <TabPanel
            value={this.state.activeTab}
            index={0}
            id="tabBtnQueueCmnPanel"
            aria-labelledby="tabBtnQueueCmn"
          >
            <TaskList list={this.state.filteredList} />
          </TabPanel>
          <TabPanel
            value={this.state.activeTab}
            index={1}
            id="tabBtnQueueMyqPanel"
            aria-labelledby="tabBtnQueueMyq"
          >
            <TaskList list={this.state.filteredList} />
          </TabPanel>
          <TabPanel
            value={this.state.activeTab}
            index={2}
            id="tabBtnQueueRefPanel"
            aria-labelledby="tabBtnQueueRef"
          >
            <TaskList list={this.state.filteredList} />
          </TabPanel>
          <TabPanel
            value={this.state.activeTab}
            index={3}
            id="tabBtnQueueCustPanel"
            aria-labelledby="tabBtnQueueCust"
          >
            <TaskList list={this.state.filteredList} />
          </TabPanel>
          <TabPanel
            value={this.state.activeTab}
            index={4}
            id="tabBtnQueueFlxPanel"
            aria-labelledby="tabBtnQueueFlx"
          >
            <TaskList list={this.state.filteredList} />
          </TabPanel>
          <TabPanel value={this.state.activeTab} index={5}>
            <Search />
          </TabPanel>
          <TabPanel value={this.state.activeTab} index={6}>
            More Services
          </TabPanel>
        </Box>
      </Layout>
    );
  }

  consts_sts_subj = {
    sts_MakerReviewCheckerReferral: "Maker Review of Checker Referral",
    src_KYC: "KYC",
    sts_FlexUpdateFailure: "Flex Update Failure",
    sts_FlexUpdate: "Flex Update",
    sts_MakerReview: "Maker Review",
    sts_terminated: "Terminated",
    sts_CheckerReviewMakerReferral: "Checker Review of Maker Referral",
    sts_EvaultUpdateFailure: "E-Vault Update Failure",
    sts_MakerMailSent: "Maker Mail Sent",
    subj_RM: "RM",
    sts_CheckerFlexRetry: "Checker Flex Retry",
    subj_EVault: "E-Vault",
    sts_eVaultUpdate: "eVault Update",
    sts_ComplianceReviewMakerReferral: "Compliance Review of Maker Referral",
    subj_Checker: "Checker",
    sts_MakerReviewRMReferral: "Maker Review of RM Referral",
    sts_MakerFlexRetry: "Maker Flex Retry",
    sts_CheckerReview: "Checker Review",
    sts_TerminatedSystem: "Terminated : System",
    subj_Compliance: "Compliance",
    sts_ComplianceReview: "Compliance Review",
    sts_MakerAwaitingCustResponseReferral:
      "Maker Awaiting Cust Response Referral",
    sts_FlexUpdateEname: "Flex Update Ename",
    subj_Customer: "Customer",
    src_staff: "Staff",
    subj_Flex: "Flex",
    sts_RMReview: "RM Review",
    sts_MakerReviewComplianceReferral: "Maker Review Of Compliance Referral",
    sts_CaseInitiated: "Case Initiated",
    sts_TLRenewalCompleted: "TL Renewal Completed",
    src_BC: "Blockchain",
    src_self: "Self",
    subj_Maker: "Maker",
  };

  filters = {
    cust: (task) =>
      (task.currentStep === "Customer" &&
        task.status === this.consts_sts_subj.sts_MakerMailSent) ||
      (task.currentStep === "Customer" &&
        task.status ===
          this.consts_sts_subj.sts_MakerAwaitingCustResponseReferral),

    cmn: (task) =>
      task.actionTaken === "Assigned" &&
      [
        this.consts_sts_subj.subj_Checker,
        this.consts_sts_subj.subj_Compliance,
        this.consts_sts_subj.subj_Maker,
      ].indexOf(task.currentStep) >= 0 &&
      [
        this.consts_sts_subj.sts_MakerFlexRetry,
        this.consts_sts_subj.sts_MakerReviewCheckerReferral,
        this.consts_sts_subj.sts_MakerReviewComplianceReferral,
      ].indexOf(task.status) < 0 &&
      !(
        (task.currentStep === this.consts_sts_subj.subj_Checker &&
          task.checker) ||
        (task.currentStep === this.consts_sts_subj.subj_Maker && task.maker) ||
        (task.currentStep === this.consts_sts_subj.subj_Compliance &&
          task.compliance)
      ),
    myq: (task) =>
      this.consts_sts_subj.subj_RM === task.currentStep ||
      (task.actionTaken === "Claimed" &&
        [
          this.consts_sts_subj.subj_Checker,
          this.consts_sts_subj.subj_Compliance,
          this.consts_sts_subj.subj_Maker,
          this.consts_sts_subj.Customer,
        ].indexOf(task.currentStep) >= 0 &&
        [
          this.consts_sts_subj.sts_MakerReviewCheckerReferral,
          this.consts_sts_subj.sts_MakerReviewComplianceReferral,
          this.consts_sts_subj.sts_MakerFlexRetry,
        ].indexOf(task.status) < 0) ||
      (task.actionTaken === "Assigned" &&
        ((task.currentStep === this.consts_sts_subj.subj_Checker &&
          task.checker) ||
          (task.currentStep === this.consts_sts_subj.subj_Maker &&
            task.maker) ||
          (task.currentStep === this.consts_sts_subj.subj_Compliance &&
            task.compliance)) &&
        [
          this.consts_sts_subj.sts_MakerReviewCheckerReferral,
          this.consts_sts_subj.sts_MakerReviewComplianceReferral,
          this.consts_sts_subj.sts_MakerFlexRetry,
          this.consts_sts_subj.sts_MakerAwaitingCustResponseReferral,
        ].indexOf(task.status) < 0),

    flx: (task) =>
      task.status === this.consts_sts_subj.sts_MakerFlexRetry ||
      task.status === this.consts_sts_subj.sts_CheckerFlexRetry ||
      task.currentStep === "Exception",

    ref: (task) =>
      [
        this.consts_sts_subj.sts_CheckerReviewMakerReferral,
        this.consts_sts_subj.sts_ComplianceReviewMakerReferral,
        this.consts_sts_subj.sts_MakerReviewCheckerReferral,
        //                tw.epv.CaseSubjectStatus.sts_MakerReviewRMReferral,
        this.consts_sts_subj.sts_MakerReviewComplianceReferral,
      ].indexOf(task.status) >= 0,
  };
}

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }
