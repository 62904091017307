import React, { Component } from "react";
import { callBpmAjax, formatDate } from "../Helpers.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

export default class FieldAudit extends Component {
  state = {
    items: [],
    page: 0,
    rowsPerPage: 5,
    sortOn: { key: "", dir: 1 },
  };
  handleSort = (key) => {
    if (key === this.state.sortOn.key) {
      let sortOn = { ...this.state.sortOn };
      sortOn.dir = sortOn.dir * -1;
      this.setState({ sortOn });
    } else {
      let sortOn = { key, dir: 1 };
      this.setState({ sortOn });
    }
    let sortedItems = this.state.items.sort((i, j) =>
      i[key] > j[key] ? this.state.sortOn.dir : -1 * this.state.sortOn.dir
    );
    console.log(key, sortedItems);
    this.setState({ items: sortedItems });
  };
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_FieldAudits, {
      refNo: this.props.refNo,
    }).then((items) => {
      this.setState({ items });
    });
  }
  render() {
    let dispList = this.state.items.slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    );
    return (
      <TableContainer>
        <Table className="FieldAuditTable">
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => {
                  this.handleSort("userRole");
                }}
              >
                User Role
              </TableCell>
              <TableCell
                onClick={() => {
                  this.handleSort("actionBy");
                }}
              >
                User
              </TableCell>
              <TableCell>Action Taken</TableCell>
              <TableCell
                onClick={() => {
                  this.handleSort("fieldName");
                }}
              >
                Field Name
              </TableCell>
              <TableCell
                onClick={() => {
                  this.handleSort("oldValue");
                }}
              >
                Old Value
              </TableCell>
              <TableCell
                onClick={() => {
                  this.handleSort("newValue");
                }}
              >
                New Value
              </TableCell>
              <TableCell
                onClick={() => {
                  this.handleSort("auditTime");
                }}
              >
                Audit Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dispList.map((adt, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell>{adt.userRole}</TableCell>
                  <TableCell>{adt.actionBy}</TableCell>
                  <TableCell>{adt.actionTaken}</TableCell>
                  <TableCell>{adt.fieldName}</TableCell>
                  <TableCell>{adt.oldValue}</TableCell>
                  <TableCell>{adt.newValue}</TableCell>
                  <TableCell>{formatDate(adt.auditTime)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.items.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={(ev, page) => {
            this.setState({ page });
          }}
          onRowsPerPageChange={(ev) => {
            this.setState({ rowsPerPage: +ev.target.value });
          }}
        />
      </TableContainer>
    );
  }
}
