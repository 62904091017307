import React, { Component } from "react";
class FormComp extends Component {
	constructor() {
		super();
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleSubmit(e) {
		e.preventDefault();
		const data = {
			string1: this.string1.value,
			string2: this.string2.value,
			string3: this.string3.value,
			string4: this.string4.value,
			string5: this.string5.value,
			string6: this.string6.value,
			string7: this.string7.value,
			string8: this.string8.value,
			string9: this.string9.value,
			string10: this.string10.value,
			date1: this.date1.value,
			date2: this.date2.value,
			date3: this.date3.value,
			date4: this.date4.value,
			date5: this.date5.value,
			file: this.filePicker.files[0],
		};
		fetch("/form/submit", {
			method: "POST",
			body: data,
		});
	}
	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<input type="text" ref={(input) => (this.string1 = input)} />
				<input type="text" ref={(input) => (this.string2 = input)} />
				<input type="text" ref={(input) => (this.string3 = input)} />
				<input type="text" ref={(input) => (this.string4 = input)} />
				<input type="text" ref={(input) => (this.string5 = input)} />
				<input type="text" ref={(input) => (this.string6 = input)} />
				<input type="text" ref={(input) => (this.string7 = input)} />
				<input type="text" ref={(input) => (this.string8 = input)} />
				<input type="text" ref={(input) => (this.string9 = input)} />
				<input type="text" ref={(input) => (this.string10 = input)} />
				<input type="date" ref={(input) => (this.date1 = input)} />
				<input type="date" ref={(input) => (this.date2 = input)} />
				<input type="date" ref={(input) => (this.date3 = input)} />
				<input type="date" ref={(input) => (this.date4 = input)} />
				<input type="date" ref={(input) => (this.date5 = input)} />
				<input type="file" ref={(input) => (this.filePicker = input)} />
				<button type="submit">Submit</button>
			</form>
		);
	}
}

export default FormComp;
