import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { callBpmAjax } from "../Components/Helpers";
import { Navigate } from "react-router-dom";
import { Component } from "react";

export default class Login extends Component {
  state = { auth: false };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ auth: true });

    // setTimeout(() => {
    //   console.log("redirect");
    //   console.log(<Navigate replace to="/" />);
    // }, 1000);

    // const data = new FormData(event.currentTarget);
    // let creds = new URLSearchParams(data);
    // let creds = {
    //   username: data.get("username"),
    //   password: data.get("password"),
    // };

    // callBpmAjax(process.env.REACT_APP_EP_LoginUrl, data, "POST", {
    //   "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    // }).then(console.log);
    //   http://localhost:8080/camunda/api/admin/auth/user/default/login/welcome
  };
  render() {
    if (this.state.auth) {
      return <Navigate replace to="/" />;
    }
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={this.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="false"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="false"
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
          </Box>
        </Box>
      </Container>
    );
  }
}
