import { Box } from "@mui/material";

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      {...other}
      sx={{ height: "calc(100% - 50px)" }}
    >
      <Box sx={{ p: 0, height: "100%" }}>{children}</Box>
    </Box>
  );
}
