import React, { Component } from "react";
import { callBpmAjax, formatDate } from "../Helpers";
import DisplayText from "../CommonUiComponents/DisplayText";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default class TLDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      tlNumber: "",
      issusingAuthority: "",
      natureOfBusiness: "",
      expiryDate: "",
      tradeName: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      addressLine4: "",
      country: "",
      isNer: "",
    };
  }
  componentDidMount() {
    callBpmAjax(process.env.REACT_APP_EP_TLDetails, {
      refNo: this.props.refNo,
      valuetype: this.props.type,
    }).then((d) => this.setState(d));
  }
  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }
  state = {};
  render() {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="TLDetails-content"
          id="TLDetails-header"
        >
          <Typography variant="h5">License Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="License Number"
                value={this.state.tlNumber}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="Issuing Authority"
                value={this.state.issuingAuthority}
              ></DisplayText>
            </Grid>
            <Grid item xs={12}>
              <DisplayText
                label="Nature of Business"
                value={this.state.natureOfBusiness}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="License Expiry Date"
                value={formatDate(this.state.expiryDate)}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="Customer Name"
                value={this.state.tradeName}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="Address Line 1"
                value={this.state.addressLine1}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="Address Line 2"
                value={this.state.addressLine2}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="Address Line 3"
                value={this.state.addressLine3}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="Address Line 4"
                value={this.state.addressLine4}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="Country"
                value={this.state.country}
              ></DisplayText>
            </Grid>
            <Grid item xs={2} md={4}>
              <DisplayText
                label="NER / Non-NER"
                value={this.state.isNer}
              ></DisplayText>
            </Grid>
            {/* <button type="button" className="btn btn-labeled icon-left">
					<span className="btn-label icon ci">
						<svg className="ci">
							<use href="#ci-compare"></use>
						</svg>
					</span>
					show original
				</button> */}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}
