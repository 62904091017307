import { Component } from "react";
import Layout from "./Layout";

class Task extends Component {
  render() {
    return (
      <Layout className="Task" title={`Task ${this.props.taskId}`}>
        <h1>this is the Task Page for {this.props.taskId}</h1>
      </Layout>
    );
  }
}

export default Task;
